body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td:empty::after{
  content: "\00a0";
}

#app {
  margin: 2em auto 5em;
  padding: 0 1.5em;
  display: flex;
  flex-direction: column;
  max-width: 768px;
}

#logo {
  width: 30%;
  min-width: 150px;
  /* max-width: 200px;  */
  height: 30%;
}

.h1, .h2, h1, h2 {
  letter-spacing: 1.5px;
}

.h3, .h4, .h5, .h6, h3, h4, h5, h6 {
  letter-spacing: 1px;
}

#navbar .dropdown-toggle::after {
  display: none;
}